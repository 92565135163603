import React, { useCallback, useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import { MenuItem, Select } from '@mui/material';

import ButtonComponent from '@/components/utility/microcomponents/Button';
import { chartColors } from '@/constants/BrandColors';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { SubscriptionInstagramDataFormatter } from '@/formatters/SubscriptionDataFormatter';
import useSubscription from '@/hooks/account/useSubscription';
import useArtist from '@/hooks/artist/useArtist';
import { SubscriptionMetricsMock } from '@/mocks/SubscriptionMocks';
import {
  AudienceMetricsGendersPerAgeModel,
  ChartDataBarModel,
  ChartDataPieModel,
  InsightsDataModel,
  InstagramAudienceMetricsModel,
} from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';
import { handleApiError } from '@/utility/api';

import BarChartComponent from '../../../../charts/BarChart';
import PieChartComponent from '../../../../charts/PieChart';
import Subscription from '../../../../subscription/Subscription';
import Loading from '../../../../utility/Loading';
import ProgressBar from '../../../../utility/ProgressBar';
import BenchmarkInsightGauge from '../../../BenchmarkInsights/BenchmarkInsightGauge';
import ViewAllModal from '../../../ViewAllModal';
import CommentsChart from './CommentsChart';
import EngagementChart from './EngagementChart';
import FollowersChart from './FollowersChart';
import LikesChart from './LikesChart';
import ViewsChart from './ViewsChart';

const InstagramInsights = () => {
  const { t } = useTranslation();

  const { artist } = useArtist();
  const { isSubscribed } = useSubscription();

  const [audienceMetrics, setAudienceMetrics] = useState<InstagramAudienceMetricsModel>();
  const [audienceMetricsLoading, setAudienceMetricsLoading] = useState<boolean>(true);

  const [ageChartData, setAgeChartData] = useState<ChartDataBarModel<AudienceMetricsGendersPerAgeModel>>();
  const [genderChartData, setGenderChartData] = useState<ChartDataPieModel[]>();

  const [chosenInsight, setChosenInsight] = useState<InsightsDataModel>();

  const dropDownOptions = [
    { value: 'countries', title: 'INSIGHTS-PAGE.COMMON.TOP-COUNTRIES' },
    { value: 'cities', title: 'INSIGHTS-PAGE.COMMON.TOP-CITIES' },
  ];
  const [dropDownValue, setDropDownValue] = useState<string>(dropDownOptions[0].value);

  const getAudienceMetrics = useCallback(async () => {
    try {
      if (!artist) return;

      const response = await MetricsAPI.getStaticInstagramAudienceMetrics({
        chartmetricId: artist?.platformIds.chartmetricId,
      });

      if (isSubscribed) {
        setAudienceMetrics(response.data);
        const codes = response.data.gendersPerAgeGroup.map((item) => item.code);

        const metricsMaps = {
          male: new Map(response.data.gendersPerAgeGroup.map((item) => [item.code, item.male])),
          female: new Map(response.data.gendersPerAgeGroup.map((item) => [item.code, item.female])),
        };

        setAgeChartData({
          name: ['Male', 'Female'],
          key: ['male', 'female'],
          color: [chartColors.barChart.blueOne, chartColors.pieChart.pink],
          data: codes.map((code) => ({
            code,
            male: metricsMaps.male.get(code) || 0,
            female: metricsMaps.female.get(code) || 0,
          })),
        });
        setGenderChartData([
          {
            name: 'Male',
            value: response.data.genders.find((item) => item.code === 'male')?.weight ?? 0,
            fill: chartColors.pieChart.blue,
          },
          {
            name: 'Female',
            value: response.data.genders.find((item) => item.code === 'female')?.weight ?? 0,
            fill: chartColors.pieChart.pink,
          },
        ]);
      } else {
        setAudienceMetrics(SubscriptionInstagramDataFormatter(response.data));

        const codes = SubscriptionMetricsMock.gendersPerAgeGroup.map((item) => item.code);

        const metricsMaps = {
          male: new Map(SubscriptionMetricsMock.gendersPerAgeGroup.map((item) => [item.code, item.male])),
          female: new Map(SubscriptionMetricsMock.gendersPerAgeGroup.map((item) => [item.code, item.female])),
        };

        setAgeChartData({
          name: ['Male', 'Female'],
          key: ['male', 'female'],
          color: [chartColors.barChart.blueOne, chartColors.pieChart.pink],
          data: codes.map((code) => ({
            code,
            male: metricsMaps.male.get(code) || 0,
            female: metricsMaps.female.get(code) || 0,
          })),
        });

        setGenderChartData([
          {
            name: 'Male',
            value: SubscriptionMetricsMock.genders.find((item) => item.code === 'male')?.weight ?? 0,
            fill: chartColors.pieChart.blue,
          },
          {
            name: 'Female',
            value: SubscriptionMetricsMock.genders.find((item) => item.code === 'female')?.weight ?? 0,
            fill: chartColors.pieChart.pink,
          },
        ]);
      }
    } catch (error: unknown) {
      handleApiError({ error });
    } finally {
      setAudienceMetricsLoading(false);
    }
  }, [artist, isSubscribed]);

  useEffect(() => {
    getAudienceMetrics();
  }, [getAudienceMetrics]);

  return (
    <>
      <ViewAllModal insights={chosenInsight} onClose={() => setChosenInsight(undefined)} />
      <BenchmarkInsightGauge platform="Instagram" />
      <div className="card mb20 mt20">
        <h3 className="mb20">{t('INSIGHTS-PAGE.COMMON.FOLLOWERS')}</h3>
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w60p w100p-lg-down">
            <FollowersChart />
            <EngagementChart />
          </div>
          <div className="flex-w40p w100p-lg-down">
            <div className="card-inner notable-followers">
              <div className="d-flex jc-space-between mb20">
                <h4>{t('INSIGHTS-PAGE.INSTAGRAM.NOTABLE-FOLLOWERS')}</h4>

                {isSubscribed ? (
                  <ButtonComponent
                    isCustom
                    onClick={() => {
                      setChosenInsight({
                        title: 'INSIGHTS-PAGE.INSTAGRAM.NOTABLE-FOLLOWERS',
                        type: 'list-item',
                        data: audienceMetrics?.notableFollowers,
                      });
                    }}
                  >
                    <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
                  </ButtonComponent>
                ) : (
                  <Subscription
                    type="element"
                    element={<img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
                  />
                )}
              </div>
              {audienceMetricsLoading && <Loading size="small" />}
              {!audienceMetricsLoading &&
                audienceMetrics?.notableFollowers &&
                audienceMetrics?.notableFollowers.length === 0 && <p>No notable followers</p>}
              {!audienceMetricsLoading &&
                audienceMetrics?.notableFollowers?.slice(0, 7).map((item, index: number) => (
                  <div
                    key={index}
                    className={`d-flex list-item ${index === 6 ? 'mb0' : ''} ${
                      !isSubscribed && index > 0 ? 'subscription-item-blur' : ''
                    }`}
                  >
                    <div>
                      <img src={item.picture} alt="" />
                    </div>
                    <div className="mt-auto mb-auto">
                      <p>{item.fullName}</p>
                      <p className="text-faded">{numberFormatter(item.followers)}</p>
                    </div>
                    <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Subscription type="banner-small" />

      <div className="card mt20">
        <h3 className="mb20">{t('INSIGHTS-PAGE.INSTAGRAM.FOLLOWER-DEMOGRAPHICS')}</h3>
        <div className="d-flex gap20 flex-wrap">
          <div className="card-inner flex-grow flex-w30p min-w300">
            <h4 className="mb20">{t('INSIGHTS-PAGE.COMMON.AGE')}</h4>
            <div className={`${!isSubscribed ? 'subscription-item-blur' : ''}`}>
              {ageChartData && ageChartData.data.length === 0 && <p>No age data</p>}
              {ageChartData && ageChartData.data.length > 0 && (
                <BarChartComponent<AudienceMetricsGendersPerAgeModel>
                  data={ageChartData}
                  xAxisKeyName="code"
                  suffix="%"
                />
              )}
            </div>
          </div>
          <div className="card-inner flex-grow flex-w30p min-w300">
            <h4 className="">{t('INSIGHTS-PAGE.COMMON.GENDER')}</h4>
            <div className={`${!isSubscribed ? 'subscription-item-blur' : ''}`}>
              {!genderChartData ? (
                <p className="mt20">No gender data</p>
              ) : (
                <PieChartComponent data={genderChartData} suffix="%" />
              )}
            </div>
          </div>
          <div className="card-inner flex-grow flex-w30p min-w300">
            <div className="d-flex jc-space-between mb20">
              <Select
                className="date-time-selector"
                value={dropDownValue}
                defaultValue={dropDownOptions[0].value}
                onChange={(item) => setDropDownValue(item.target.value)}
              >
                {dropDownOptions.map((item, index: number) => (
                  <MenuItem value={item.value} key={index}>
                    {t(item.title)}
                  </MenuItem>
                ))}
              </Select>

              {isSubscribed ? (
                <ButtonComponent
                  isCustom
                  onClick={() => {
                    setChosenInsight({
                      title: 'INSIGHTS-PAGE.COMMON.LOCATION',
                      subtitle: 'By followers',
                      type: 'progress-location',
                      data: dropDownValue === 'countries' ? audienceMetrics?.topCountries : audienceMetrics?.topCities,
                    });
                  }}
                >
                  <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
                </ButtonComponent>
              ) : (
                <Subscription
                  type="element"
                  element={<img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
                />
              )}
            </div>
            <div>
              {audienceMetricsLoading && <Loading size="small" />}
              {!audienceMetricsLoading &&
                dropDownValue === 'countries' &&
                audienceMetrics?.topCountries &&
                audienceMetrics?.topCountries?.length === 0 && <p>No top countries</p>}
              {!audienceMetricsLoading &&
                dropDownValue === 'cities' &&
                audienceMetrics?.topCities &&
                audienceMetrics?.topCities?.length === 0 && <p>No top cities</p>}
              {!audienceMetricsLoading &&
                dropDownValue === 'countries' &&
                audienceMetrics?.topCountries &&
                audienceMetrics?.topCountries?.length > 0 &&
                audienceMetrics?.topCountries?.slice(0, 5).map((item, index) => (
                  <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                    <div className="d-flex jc-space-between">
                      <p className="small">
                        <ReactCountryFlag
                          countryCode={item.code}
                          style={{
                            fontSize: '2em',
                          }}
                        />
                        <span className="pl8">{item.name}</span>
                      </p>
                      <p className="small text-faded mt-auto mb-auto">{numberFormatter(item.followers)}</p>
                    </div>
                    <ProgressBar progress={item.percent} title="" label={''} />
                  </div>
                ))}
              {!audienceMetricsLoading &&
                dropDownValue === 'cities' &&
                audienceMetrics?.topCities?.slice(0, 5).map((item, index) => (
                  <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                    <div className="d-flex jc-space-between">
                      <p className="small">
                        <span className="">{item.name}</span>
                      </p>
                      <p className="small text-faded mt-auto mb-auto">{numberFormatter(item.followers)}</p>
                    </div>
                    <ProgressBar progress={item.percent} title="" label={''} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="card mt20">
        <h3 className="mb20">{t('INSIGHTS-PAGE.INSTAGRAM.POST-STATISTICS')}</h3>
        <div className="d-flex gap20 flex-wrap">
          <CommentsChart />
          <ViewsChart />
          <LikesChart />
        </div>
      </div>

      <div className="card mt20">
        <h3 className="mb20">{t('INSIGHTS-PAGE.INSTAGRAM.INTERESTS')}</h3>
        <div className="d-flex gap20 flex-wrap">
          <div className="card-inner flex-grow flex-w50p w100p-lg-down">
            <div className="d-flex jc-space-between mb20">
              <h4>{t('INSIGHTS-PAGE.INSTAGRAM.BRAND-AFFINITY')}</h4>

              {isSubscribed ? (
                <ButtonComponent
                  isCustom
                  onClick={() => {
                    setChosenInsight({
                      title: 'INSIGHTS-PAGE.INSTAGRAM.BRAND-AFFINITY',
                      type: 'progress-affinity',
                      data: audienceMetrics?.brandAffinities,
                    });
                  }}
                >
                  <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
                </ButtonComponent>
              ) : (
                <Subscription
                  type="element"
                  element={<img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
                />
              )}
            </div>
            <div>
              {audienceMetricsLoading && <Loading size="small" />}
              {!audienceMetricsLoading && audienceMetrics?.brandAffinities.length === 0 && <p>No brand affinities</p>}
              {!audienceMetricsLoading &&
                audienceMetrics?.brandAffinities?.slice(0, 7).map((item, index) => (
                  <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                    <div className="d-flex jc-space-between">
                      <p className="small">{item.name}</p>
                      <p className="small text-faded">{item.affinity.toFixed(2)}x</p>
                    </div>
                    <ProgressBar progress={item.affinity * 10} tooltip={item.affinity.toFixed(2)} title="" label={''} />
                  </div>
                ))}
            </div>
          </div>
          <div className="card-inner flex-grow flex-w50p w100p-lg-down">
            <div className="d-flex jc-space-between mb20">
              <h4>{t('INSIGHTS-PAGE.INSTAGRAM.INTEREST-AFFINITY')}</h4>

              {isSubscribed ? (
                <ButtonComponent
                  isCustom
                  onClick={() => {
                    setChosenInsight({
                      title: 'INSIGHTS-PAGE.INSTAGRAM.INTEREST-AFFINITY',
                      type: 'progress-affinity',
                      data: audienceMetrics?.interestAffinities,
                    });
                  }}
                >
                  <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
                </ButtonComponent>
              ) : (
                <Subscription
                  type="element"
                  element={<img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
                />
              )}
            </div>
            <div>
              {audienceMetricsLoading && <Loading size="small" />}
              {!audienceMetricsLoading && audienceMetrics?.interestAffinities.length === 0 && (
                <p>No interest affinities</p>
              )}

              {!audienceMetricsLoading &&
                audienceMetrics?.interestAffinities?.slice(0, 7).map((item, index) => (
                  <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                    <div className="d-flex jc-space-between">
                      <p className="small">{item.name}</p>
                      <p className="small text-faded">{item.affinity.toFixed(2)}x</p>
                    </div>
                    <ProgressBar progress={item.affinity * 10} tooltip={item.affinity.toFixed(2)} title="" label={''} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstagramInsights;
