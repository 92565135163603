import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BenchmarkInsightsCTACards = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const platforms = useMemo(() => {
    return [
      { name: 'Spotify', link: '?tab=music&platform=spotify' },
      { name: 'Instagram', link: '?tab=socials&platform=instagram' },
      { name: 'TikTok', link: '?tab=socials&platform=tiktok' },
      { name: 'YouTube', link: '?tab=music&platform=youtube' },
    ];
  }, []);

  return (
    <div className={`to-do-card-border flex-w50p w100p-lg-down h-fit`}>
      <div className={`to-do-card`}>
        <h3>{t('BENCHMARK-INSIGHTS.BENCHMARK-INSIGHTS')}</h3>
        <p className="text-faded mt16">
          {t('BENCHMARK-INSIGHTS.WANT-TO-SEE-HOW-YOU-COMPARE')}{' '}
          <a className="text-white bold">
            {platforms.map((platform, index) => (
              <a
                href={`/insights${platform.link}`}
                className="text-white"
                key={index}
                onClick={() => {
                  navigate(`/insights${platform.link}`);
                }}
              >
                <span className="underline">{platform.name}</span>

                {index === platforms.length - 2 ? ' and ' : index < platforms.length - 1 ? ', ' : ''}
              </a>
            ))}
            ?{' '}
          </a>
          {t('BENCHMARK-INSIGHTS.NOW-YOU-CAN-VISIT-EACH-TAB')}
        </p>
      </div>
    </div>
  );
};

export default BenchmarkInsightsCTACards;
