import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import { MenuItem, Select } from '@mui/material';
import { format } from 'date-fns';

import ButtonComponent from '@/components/utility/microcomponents/Button';
import { DateTimes } from '@/constants/DateTimes';
import numberFormatter from '@/formatters/ShortNumberConverter';
import {
  SubscriptionArtistDataFormatter,
  SubscriptionPlaylistDataFormatter,
  SubscriptionTracksDataFormatter,
} from '@/formatters/SubscriptionDataFormatter';
import useSubscription from '@/hooks/account/useSubscription';
import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlatformArtistMetrics from '@/hooks/metrics/usePlatformArtistMetrics';
import usePlaylistMetrics from '@/hooks/metrics/usePlaylistMetrics';
import useSpotifyAudienceLocationMetrics from '@/hooks/metrics/useSpotifyAudienceLocationMetrics';
import useSpotifyArtistTopTracks from '@/hooks/spotify/useSpotifyArtistTopTracks';
import useSpotifySimilarArtists from '@/hooks/spotify/useSpotifySimilarArtists';
import { SubscriptionMetricsMock } from '@/mocks/SubscriptionMocks';
import {
  InsightsDataModel,
  MetricsPlaylistsModel,
  SpotifyAudienceLocationsDataMetricsModel,
  SpotifyListenersFollowersMetricsModel,
  SpotifyPopularityConversionMetricsModel,
} from '@/models/Metrics';
import { SpotifyArtistModel, SpotifySearchDataItemsModel } from '@/models/Spotify';

import LineChartComponent from '../../../charts/LineChart';
import Subscription from '../../../subscription/Subscription';
import Loading from '../../../utility/Loading';
import DateTimeSelector from '../../../utility/microcomponents/DateTimeSelector';
import ProgressBar from '../../../utility/ProgressBar';
import BenchmarkInsightGauge from '../../BenchmarkInsights/BenchmarkInsightGauge';
import ViewAllModal from '../../ViewAllModal';

const SpotifyInsights = () => {
  const { t } = useTranslation();

  const { isSubscribed } = useSubscription();

  const [topCountries, setTopCountries] = useState<SpotifyAudienceLocationsDataMetricsModel[]>();
  const [topCities, setTopCities] = useState<SpotifyAudienceLocationsDataMetricsModel[]>();
  const [topTracks, setTopTracks] = useState<SpotifySearchDataItemsModel[]>();
  const [featuredPlaylists, setFeaturedPlaylists] = useState<MetricsPlaylistsModel[]>();
  const [similarArtists, setSimilarArtists] = useState<SpotifyArtistModel[]>();

  const [chosenInsight, setChosenInsight] = useState<InsightsDataModel>();
  const [selectedStartDate, setSelectedStartDate] = useState<string>();

  const [topCountriesLoading, setTopCountriesLoading] = useState<boolean>(true);
  const [topSongsloading, setTopSongsloading] = useState<boolean>(true);
  const [playlistLoading, setPlaylistLoading] = useState<boolean>(true);
  const [competitionLoading, setCompetitionLoading] = useState<boolean>(true);

  const chartDropDownOptions = [
    {
      value: 'listenersVsFollowers',
      title: 'INSIGHTS-PAGE.SPOTIFY.LISTENERS-VS-FOLLOWERS',
    },
    {
      value: 'popularityVsConversion',
      title: 'INSIGHTS-PAGE.SPOTIFY.POPULARITY-VS-CONVERSION',
    },
  ];
  const [chartDropDownValue, setChartDropDownValue] = useState<string>(chartDropDownOptions[0].value);

  const dropDownOptions = [
    { value: 'countries', title: 'INSIGHTS-PAGE.COMMON.TOP-COUNTRIES' },
    { value: 'cities', title: 'INSIGHTS-PAGE.COMMON.TOP-CITIES' },
  ];
  const [dropDownValue, setDropDownValue] = useState<string>(dropDownOptions[0].value);

  const { platformArtistMetrics } = usePlatformArtistMetrics({
    platform: 'spotify',
    fromDate: selectedStartDate,
    toDate: DateTimes.yesterdayDate,
  });

  const followersVsListenersDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Followers',
        color: 'blue',
        key: 'followers',
        dataMap: new Map(
          platformArtistMetrics?.followers.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
      {
        name: 'Listeners',
        color: 'pink',
        key: 'listeners',
        dataMap: new Map(
          platformArtistMetrics?.listeners.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const followersVsListenersChartData = useLineChartData<SpotifyListenersFollowersMetricsModel>({
    startDate: selectedStartDate,
    data: followersVsListenersDataMap,
  });

  const popularityVsConversionDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Popularity',
        color: 'blue',
        key: 'popularity',
        dataMap: new Map(
          platformArtistMetrics?.popularity.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
      {
        name: 'Conversion',
        color: 'pink',
        key: 'conversion',
        dataMap: new Map(
          platformArtistMetrics?.conversion.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const popularityVsConversionChartData = useLineChartData<SpotifyPopularityConversionMetricsModel>({
    startDate: selectedStartDate,
    data: popularityVsConversionDataMap,
  });

  const { spotifyAudienceLocationMetrics } = useSpotifyAudienceLocationMetrics();

  useEffect(() => {
    if (!spotifyAudienceLocationMetrics) return;

    if (isSubscribed) {
      setTopCities(spotifyAudienceLocationMetrics.cities);
      setTopCountries(spotifyAudienceLocationMetrics.countries);
    } else {
      setTopCities([spotifyAudienceLocationMetrics.cities[0], ...SubscriptionMetricsMock.spotifyTopCities]);
      setTopCountries([spotifyAudienceLocationMetrics.countries[0], ...SubscriptionMetricsMock.spotifyTopCountries]);
    }

    setTopCountriesLoading(false);
  }, [isSubscribed, spotifyAudienceLocationMetrics]);

  const { spotifyArtistTopTracks } = useSpotifyArtistTopTracks();

  useEffect(() => {
    if (!spotifyArtistTopTracks) return;

    if (isSubscribed) {
      setTopTracks(spotifyArtistTopTracks);
    } else {
      setTopTracks(SubscriptionTracksDataFormatter(spotifyArtistTopTracks));
    }
    setTopSongsloading(false);
  }, [isSubscribed, spotifyArtistTopTracks]);

  const { playlistMetrics } = usePlaylistMetrics({ platform: 'spotify' });

  useEffect(() => {
    if (!playlistMetrics) return;

    if (isSubscribed) {
      setFeaturedPlaylists(playlistMetrics);
    } else {
      setFeaturedPlaylists(SubscriptionPlaylistDataFormatter(playlistMetrics));
    }

    setPlaylistLoading(false);
  }, [isSubscribed, playlistMetrics]);

  const { spotifySimilarArtists } = useSpotifySimilarArtists();

  useEffect(() => {
    if (!spotifySimilarArtists) return;

    if (isSubscribed) {
      setSimilarArtists(spotifySimilarArtists);
    } else {
      setSimilarArtists(SubscriptionArtistDataFormatter(spotifySimilarArtists));
    }

    setCompetitionLoading(false);
  }, [isSubscribed, spotifySimilarArtists]);

  const handleDate = useCallback((date: string) => {
    setSelectedStartDate(date);
  }, []);

  return (
    <>
      <ViewAllModal insights={chosenInsight} onClose={() => setChosenInsight(undefined)} />
      <BenchmarkInsightGauge platform="Spotify" />
      <div className="d-flex flex-item-wrap gap20 mb20 mt20">
        <div className="card flex-w60p w100p-lg-down">
          <div className="d-flex jc-space-between flex-wrap mb8">
            <Select
              className="spotify-chart-selector mb8"
              value={chartDropDownValue}
              defaultValue={chartDropDownOptions[0].value}
              onChange={(item) => {
                setChartDropDownValue(item.target.value);
              }}
            >
              {chartDropDownOptions.map((item, index: number) => (
                <MenuItem value={item.value} key={index}>
                  {t(item.title)}
                </MenuItem>
              ))}
            </Select>
            <DateTimeSelector dateOutput={handleDate} defaultRange="This year" />
          </div>
          <LineChartComponent<SpotifyListenersFollowersMetricsModel | SpotifyPopularityConversionMetricsModel>
            data={
              chartDropDownValue === 'listenersVsFollowers'
                ? followersVsListenersChartData
                : popularityVsConversionChartData
            }
            height={350}
          />
        </div>
        <div className="card flex-w40p w100p-lg-down">
          <div className="d-flex jc-space-between mb20">
            <Select
              className="date-time-selector"
              value={dropDownValue}
              defaultValue={dropDownOptions[0].value}
              onChange={(item) => {
                setDropDownValue(item.target.value);
              }}
            >
              {dropDownOptions.map((item, index: number) => (
                <MenuItem value={item.value} key={index}>
                  {t(item.title)}
                </MenuItem>
              ))}
            </Select>
            {isSubscribed ? (
              <ButtonComponent
                isCustom
                onClick={() => {
                  setChosenInsight({
                    title:
                      dropDownValue === 'countries'
                        ? 'INSIGHTS-PAGE.COMMON.TOP-COUNTRIES'
                        : 'INSIGHTS-PAGE.COMMON.TOP-CITIES',
                    subtitle: 'By followers',
                    type: 'progress-location',
                    data: dropDownValue === 'countries' ? topCountries : topCities,
                  });
                }}
              >
                <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
              </ButtonComponent>
            ) : (
              <Subscription
                type="element"
                element={<img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
              />
            )}
          </div>
          <div>
            {topCountriesLoading && <Loading size="small" />}
            {dropDownValue === 'countries' &&
              !topCountriesLoading &&
              topCountries &&
              topCountries.slice(0, 5).map((item, index) => (
                <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                  <div className={`d-flex jc-space-between `}>
                    <p className="small">
                      <ReactCountryFlag
                        countryCode={item?.countryCode}
                        style={{
                          fontSize: '2em',
                        }}
                      />
                      <span className="pl8">{item?.name}</span>
                    </p>
                    <p className="small text-faded mt-auto mb-auto">{numberFormatter(item?.monthlyListeners)}</p>
                  </div>
                  <ProgressBar progress={item?.percentageOfTotalMonthlyListeners} title="" label={''} />
                </div>
              ))}
            {dropDownValue === 'cities' &&
              !topCountriesLoading &&
              topCities &&
              topCities.slice(0, 5).map((item, index) => (
                <div key={index} className={`mb20 ${!isSubscribed && index > 0 ? 'subscription-item-blur' : ''}`}>
                  <div className={`d-flex jc-space-between`}>
                    <p className="small">
                      <ReactCountryFlag
                        countryCode={item.countryCode}
                        style={{
                          fontSize: '2em',
                        }}
                      />
                      <span className="pl8">{item.name}</span>
                    </p>
                    <p className="small text-faded mt-auto mb-auto">{numberFormatter(item.monthlyListeners)}</p>
                  </div>
                  <ProgressBar progress={item.percentageOfTotalMonthlyListeners} title="" label={''} />
                </div>
              ))}
          </div>
        </div>
      </div>
      <Subscription type="banner-small" />
      <div className="d-flex mt20 gap20 flex-wrap">
        <div className="card flex-w30p flex-grow w100p-lg-down">
          <div className="d-flex jc-space-between mb20">
            <h4>{t('INSIGHTS-PAGE.COMMON.TOP-SONGS')}</h4>

            {isSubscribed ? (
              <ButtonComponent
                isCustom
                onClick={() => {
                  setChosenInsight({
                    title: 'INSIGHTS-PAGE.COMMON.TOP-SONGS',
                    type: 'top-songs',
                    data: topTracks,
                  });
                }}
              >
                <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
              </ButtonComponent>
            ) : (
              <Subscription
                type="element"
                element={<img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
              />
            )}
          </div>
          {topSongsloading && <Loading size="small" />}
          {!topSongsloading &&
            topTracks?.slice(0, 5).map((item, index: number) => (
              <div
                key={index}
                className={`d-flex gap20 mt20 list-item campaign ${
                  !isSubscribed && index > 0 ? 'subscription-item-blur' : ''
                }`}
                onClick={() => {
                  window.open(item.external_urls.spotify);
                }}
              >
                <img src={item?.album.images[2].url} alt="" />
                <div className="mt-auto mb-auto">
                  <p>{item?.name}</p>
                  <p className="text-faded">
                    {item?.popularity} {t('INSIGHTS-PAGE.SPOTIFY.POPULARITY-LOWER')}
                  </p>
                </div>
                <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
              </div>
            ))}
        </div>
        <div className="card flex-w30p flex-grow w100p-lg-down">
          <div className="d-flex jc-space-between mb20">
            <h4>{t('INSIGHTS-PAGE.SPOTIFY.FEATURED-PLAYLISTS')}</h4>
            {isSubscribed ? (
              <ButtonComponent
                isCustom
                onClick={() => {
                  setChosenInsight({
                    title: 'INSIGHTS-PAGE.SPOTIFY.FEATURED-PLAYLISTS',
                    type: 'featured-playlists',
                    data: featuredPlaylists,
                  });
                }}
              >
                <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
              </ButtonComponent>
            ) : (
              <Subscription
                type="element"
                element={<img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
              />
            )}
          </div>
          {playlistLoading && <Loading size="small" />}
          {!playlistLoading && (!featuredPlaylists || featuredPlaylists.length === 0) && <p>No featured playlists</p>}
          {!playlistLoading &&
            featuredPlaylists?.slice(0, 5).map((item, index: number) => (
              <div
                key={index}
                className={`d-flex gap20 mt20 list-item campaign ${
                  !isSubscribed && index > 0 ? 'subscription-item-blur' : ''
                }`}
                onClick={() => {
                  window.open(`https://open.spotify.com/playlist/${item.playlist.spotifyId}`);
                }}
              >
                <img src={item?.playlist?.coverImageUrl} alt="" />
                <div className="mt-auto mb-auto">
                  <p>{item?.playlist.name}</p>
                  <p className="text-faded">
                    {numberFormatter(item?.playlist.followers)} {t('INSIGHTS-PAGE.COMMON.FOLLOWERS-LOWER')}
                  </p>
                </div>
                <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
              </div>
            ))}
        </div>
        <div className="card flex-w30p flex-grow w100p-lg-down">
          <div className="d-flex jc-space-between mb20">
            <h4>{t('INSIGHTS-PAGE.SPOTIFY.YOUR-COMPETITION')}</h4>

            {isSubscribed ? (
              <ButtonComponent
                isCustom
                onClick={() => {
                  setChosenInsight({
                    title: 'INSIGHTS-PAGE.SPOTIFY.YOUR-COMPETITION',
                    type: 'similar-artists',
                    data: similarArtists,
                  });
                }}
              >
                <p className="pt4 cursor-pointer">{t('COMMON.VIEW-ALL')}</p>
              </ButtonComponent>
            ) : (
              <Subscription
                type="element"
                element={<img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
              />
            )}
          </div>
          {competitionLoading && <Loading size="small" />}
          {!competitionLoading &&
            similarArtists?.slice(0, 5).map((item, index: number) => (
              <div
                key={index}
                className={`d-flex gap20 mt20 list-item campaign ${
                  !isSubscribed && index > 0 ? 'subscription-item-blur' : ''
                }`}
                onClick={() => {
                  window.open(`https://open.spotify.com/artist/${item.id}`);
                }}
              >
                <img src={item?.images[0].url} alt="" />
                <div className="mt-auto mb-auto">
                  <p>{item?.name}</p>
                </div>
                <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default SpotifyInsights;
